import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/grunt-watch-livereload-with-javascript-sass",
  "date": "2015-08-29",
  "title": "GRUNT WATCH & LIVERELOAD WITH JAVASCRIPT & SASS",
  "author": "admin",
  "tags": ["development", "javascript", "grunt"],
  "featuredImage": "feature.jpg",
  "excerpt": "Grunt is a task runner that has a ton of plugins for doing things like compiling LESS/SASS into CSS, concatenating JavaScript, minifying, etc. It also has a package for watching files (the same way CodeKit and Prepros does) and firing off certain tasks when they’re modified."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Given that Grunt has these features, once it’s integrated into a project, it makes sense to leverage it for the real-time compiling and reloading of JS/LESS/SASS files. You’ll be able to reuse many of the same build tasks and it’ll allow other developers (who may not have CodeKit installed) to easily follow the same workflow when they open your project. Here’s how to do it.`}</p>
    <p>{`Suppose we have a project using JavaScript and SASS. The file structure can look as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`tree
/src
  /jquery.js
  /functions.js
  /app.js
/sass
  /_header.sass
  /_footer.sass
  /_body.sass
  /main.sass
/public
  /css
    /style.css
  /js
    /main.min.js
`}</code></pre>
    <p>{`We have 3 JavaScript files in the `}<strong parentName="p">{`/src`}</strong>{` directory that need to be combined and minified into `}<strong parentName="p">{`/public/js/main.min.js`}</strong>{`. We also have a `}<strong parentName="p">{`main.sass`}</strong>{` file (that imports the other underscored .sass files) and compiles down into `}<strong parentName="p">{`/public/css/style.css`}</strong>{`. `}</p>
    <p>{`In `}<inlineCode parentName="p">{`CodeKit`}</inlineCode>{`, you’d drag this whole folder into your window. You would configure some options and set the output paths, then you’d be good to go. `}<inlineCode parentName="p">{`CodeKit`}</inlineCode>{` would start watching the files and automatically recompile.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`Grunt`}</inlineCode>{`, however, we need to explain these procedures via a configuration file. Don’t worry. It’s easy! Here’s what you need to do.`}</p>
    <Message type="info" title="" content="This seems like tedious, and a lot of steps, but often times many of them are done already — especially if you’re working on a Node.js project." mdxType="Message" />
    <h2>{`1. Install Node.js (with NPM)`}</h2>
    <p>{`Even if your project isn’t written in Node.js, you’ll need it to access these tool. Just go to nodejs.org and grab the latest copy. This will also install NPM (the Node.js Package Manager).`}</p>
    <h2>{`2. Install Grunt`}</h2>
    <p>{`Because you’ll be using Grunt a lot, it’s easiest to just install it globally. You can do that by running this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`sudo npm install -g grunt-cli
`}</code></pre>
    <Message type="warn" title="" content="After the installation is complete, you may need to exit your terminal window and reopen it again to gain access to the **grunt** command." mdxType="Message" />
    <h2>{`3. Configure NPM’s package.json with Grunt Plugins`}</h2>
    <p>{`Ok, NPM is installed. Now we need to configure the necessary packages so that all the `}<inlineCode parentName="p">{`Grunt plugins`}</inlineCode>{` we need will be downloaded. You’ll need to create a `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file with the contents below. If this file already exists, just add the entries in the dependencies section.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "name": "my-project",
  "version": "0.1.0",
  "dependencies": {
    "grunt-cli": "latest",
    "grunt-contrib-concat": "latest",
    "grunt-contrib-sass": "latest",
    "grunt-contrib-uglify": "latest",
    "grunt-contrib-watch": "latest"
  }
}
`}</code></pre>
    <h2>{`4. Install Grunt Plugins via NPM`}</h2>
    <p>{`Now that we’ve configured our `}<strong parentName="p">{`package.json`}</strong>{` file with the packages we need, let’s install them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install
`}</code></pre>
    <h2>{`5. Configure Gruntfile.js`}</h2>
    <p>{`Ok, everything is installed. The last thing we need to do is setup Grunt’s configuration file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = function(grunt) {

  grunt.registerTask('watch', [ 'watch' ]);

  grunt.initConfig({
    concat: {
      js: {
        options: {
          separator: ';'
        },
        src: [
          'src/*.js'
        ],
        dest: 'public/js/main.min.js'
      },
    },
    uglify: {
      options: {
        mangle: false
      },
      js: {
        files: {
          'public/js/main.min.js': ['public/js/main.min.js']
        }
      }
    },
    sass: {
      style: {
        files: {
          "public/css/style.css": "sass/main.sass"
        }
      }
    },
    watch: {
      js: {
        files: ['src/*.js'],
        tasks: ['concat:js', 'uglify:js'],
        options: {
          livereload: true,
        }
      },
      css: {
        files: ['sass/*.sass'],
        tasks: ['sass:style'],
        options: {
          livereload: true,
        }
      }
    }
  });

  grunt.loadNpmTasks('grunt-contrib-concat');
  grunt.loadNpmTasks('grunt-contrib-uglify');
  grunt.loadNpmTasks('grunt-contrib-sass');
  grunt.loadNpmTasks('grunt-contrib-watch');

};
`}</code></pre>
    <p>{`This configuration file setups up a number of tasks for concatenating, compiling and minimizing the JS and SASS. It also tells Grunt to execute the tasks anytime the files are modified.`}</p>
    <h2>{`6. Run Grunt Command to Watch Your Files`}</h2>
    <p>{`Now that everything is downloaded and configured, we simply need to run a single command to make Grunt start watching our files.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`grunt watch
`}</code></pre>
    <p>{`Run this command (in your project’s root directory) anytime you want Grunt to start watching your files. To stop the process, simply use `}<strong parentName="p">{`control + c`}</strong>{` on your keyboard.`}</p>
    <p>{`You’ll notice that there are a few live reload flags in the Grunt configuration file above. This tells Grunt to alert LiveReload that files have changed so that it can automatically refresh your browser window. For this functionality to work, you can purchase LiveReload or just install the free `}<strong parentName="p">{`LiveReload Chrome Extension`}</strong>{`. (Make sure it’s enabled by clicking the icon in your browser’s toolbar.)`}</p>
    <Message type="info" title="" content="Please note that if your files are structured or named differently, you may need to adjust the configuration above." mdxType="Message" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      